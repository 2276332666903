import React from 'react';

const App = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 w-screen">
      <div className="text-center" style={{ maxWidth: "500px"}}>
        <img className="w-64 inline-block" src="/not-found.png"/>
        <h1 className="text-6xl font-bold text-gray-800">406</h1>
        <p className="text-4xl text-gray-600 mt-4">Page Not Here</p>
        <p className="mt-4 text-gray-600">The server has permanently moved the requested resource to a different server as part of organizational load balancing or in response to server decommissioning schedules. This relocation is typically managed through dynamic DNS adjustments or internal routing updates, but the new server's location has not been propagated fully or is obscured due to security configurations. Clients should retry their requests after a brief delay, or follow redirection policies if provided.</p>
        <p className="mt-4 text-sm text-gray-600">If you believe you are seeing this page in error, please contact your system administrator.</p>
      </div>
    </div>
  );
};

export default App;
